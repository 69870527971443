const options = {
  bars: {
    number: 30,
    numberIncrement: 7
  },
  rings: {
    edge: 240,
    edgeIncrement: 60,

    // more than enough to fill the max content width
    // 240 + (60 * 15) = 1140
    total: 15
  }
}

const generate = () => {
  const fragment = document.createDocumentFragment()

  let barNode
  let barNum
  let barsTotal
  let barsRotation
  let dotNode
  let edge
  let edgeHalf
  let ringNode
  let ringNum

  for (ringNum = 0; ringNum < options.rings.total; ringNum++) {
    // create ring
    ringNode = document.createElement('div')
    ringNode.classList.add('ring')
    ringNode.classList.add(`ring-${ ringNum + 1 }`)

    // calculate ring size
    edge = options.rings.edge + (options.rings.edgeIncrement * ringNum)
    edgeHalf = edge / 2

    // set ring size and spacing
    ringNode.style.width = `${ edge }px`
    ringNode.style.height = `${ edge }px`
    ringNode.style.marginTop = `-${ edgeHalf }px`
    ringNode.style.marginLeft = `-${ edgeHalf }px`

    // calculate # of bars needed
    barsTotal = options.bars.number + (options.bars.numberIncrement * ringNum)

    // calculate rotation per bar
    barsRotation = 360 / barsTotal

    for (barNum = 0; barNum < barsTotal; barNum++) {
      // create bar
      barNode = document.createElement('div')
      barNode.classList.add('bar')

      // create dot
      dotNode = document.createElement('div')
      dotNode.classList.add('dot')

      // set bar rotation
      barNode.style.transform = `rotate(${ barsRotation * barNum }deg)`

      // add dot to bar
      barNode.append(dotNode)

      // add bar to ring
      ringNode.append(barNode)
    }

    // add to frag
    fragment.append(ringNode)

    // reset bar index
    barNum = 0
  }

  return fragment
}

const enter = () => {
  const root = document.getElementById('rings')

  if (!root) return

  root.append(generate())
}

const exit = () => {

}

export default {
  enter,
  exit
}
