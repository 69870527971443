import cache from '../utils/cache.js'

const viewport = () => {
  cache.window = {}

  const capture = () => {
    cache.window.width = window.innerWidth
    cache.window.height = window.innerHeight
  }

  const handleResize = () => {
    clearTimeout(cache.window.timeout)
    cache.window.timeout = setTimeout(capture, 250)
  }

  capture()

  window.addEventListener('resize', handleResize)
}

export default viewport
