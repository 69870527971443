import detectWebP from './utils/detect-webp.js'
import viewport from './providers/viewport.js'

import * as pages from '../html/pages/*'
import route from './providers/route.js'
import router from './providers/router.js'

if (module.hot) {
  module.hot.accept()
}

const boot = event => {
  // run feature detects
  detectWebP()

  // add viewport data to cache
  viewport()

  // define routes
  const routes = {
    '/': route({
      className: 'home',
      meta: {
        desc: 'PAR21 is a boutique investment bank focused on special situations across proprietary sourcing and private debt & equity capital markets.',
        themeColor: '#0C1923',
        title: false
      },
      path: pages['home.html']
    }),

    '/about': route({
      className: 'about',
      meta: {
        desc: 'PAR21 is built on long-term institutional relationships and unmatched speed of execution.',
        themeColor: '#0C1923',
        title: 'About'
      },
      path: pages['about.html']
    }),

    '/buyers-club': route({
      className: 'buyers-club',
      meta: {
        desc: `PAR21's Buyers' Club is an exclusive group of investors that receive priority exposure to proprietary deals.`,
        themeColor: '#0C1923',
        title: `Buyers' Club`
      },
      path: pages['buyers-club.html']
    }),

    '/careers': route({
      className: 'careers',
      meta: {
        desc: 'PAR21 is hiring. Get in touch to learn more and join the team.',
        themeColor: '#9BB7C6',
        title: 'Careers'
      },
      path: pages['careers.html']
    }),

    '/contact': route({
      className: 'contact',
      meta: {
        desc: 'Get in touch with PAR21.',
        themeColor: '#9BB7C6',
        title: 'Contact'
      },
      path: pages['contact.html']
    }),

    '/leadership': route({
      className: 'leadership',
      meta: {
        desc: 'Our team has a proven track record across the United States, Canada, and Europe.',
        themeColor: '#9BB7C6',
        title: 'Leadership'
      },
      path: pages['leadership.html']
    }),

    '/transactions': route({
      className: 'transactions',
      meta: {
        desc: 'PAR21 transactions span industries and situations - from debt disposition, to capital raises, to equity raises, and more.',
        themeColor: '#9BB7C6',
        title: 'Transactions'
      },
      path: pages['transactions.html']
    })
  }

  // start the router
  router(routes).listen()
}

document.addEventListener('DOMContentLoaded', boot)
