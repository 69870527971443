const _temp0 = require("./about.html");
const _temp1 = require("./buyers-club.html");
const _temp2 = require("./careers.html");
const _temp3 = require("./contact.html");
const _temp4 = require("./home.html");
const _temp5 = require("./leadership.html");
const _temp6 = require("./transactions.html");
module.exports = {
  "about.html": _temp0,
  "buyers-club.html": _temp1,
  "careers.html": _temp2,
  "contact.html": _temp3,
  "home.html": _temp4,
  "leadership.html": _temp5,
  "transactions.html": _temp6
}