import jumper from 'jump.js'

let button
let target

const handleClick = event => {
  event.preventDefault()
  jumper(target, { duration: 1000 })
}

const enter = () => {
  button = document.querySelector('[data-jump-button]')
  target = document.querySelector('[data-jump-target]')

  if (!button || !target) return

  button.addEventListener('click', handleClick)
}

const exit = () => {
  if (!button || !target) return

  button.removeEventListener('click', handleClick)
}

export default {
  enter,
  exit
}
