const OFFSET = 10

let nodes
let observer

const setup = () => {
  // define change handler
  const handleChange = (entries, observer) => {
    entries.forEach(entry => {
      // exit early if not intersecting
      if (!entry.isIntersecting) return

      // stop watching
      observer.unobserve(entry.target)

      // request frame
      window.requestAnimationFrame(() => {
        // add inview class
        entry.target.classList.add('is-inview')
      })
    })
  }

  // create observer
  observer = new window.IntersectionObserver(handleChange, {
    root: null,
    rootMargin: `0px 0px -${ OFFSET }% 0px`,
    threshold: 0.01
  })
}

const enter = () => {
  // if it doesn't exist, setup observer
  if (!observer) {
    setup()
  }

  // cache nodes
  nodes = [...document.querySelectorAll('[data-inview]')]

  // add nodes to observer
  nodes.forEach(node => observer.observe(node))
}

const exit = () => {
  // remove nodes from observer
  nodes.forEach(node => observer.unobserve(node))
}

export default {
  enter,
  exit
}
