import scroll from '../utils/scroll-lock.js'

let button
let menu
let menuLinks
let open

const handleToggleClick = event => {
  event.preventDefault()

  if (!open) {
    scroll.lock()
    open = true
  } else {
    scroll.unlock()
    open = false
  }

  menu.classList.toggle('is-active')
  button.classList.toggle('is-active')
}

const handleMenuLinkClick = event => {
  scroll.unlock()
  open = false
}

const enter = () => {
  menu = document.querySelector('.menu')
  menuLinks = [...menu.querySelectorAll('.menu-link')]
  button = document.querySelector('.nav-switch')

  button.addEventListener('click', handleToggleClick)
  menuLinks.forEach(link => link.addEventListener('click', handleMenuLinkClick))
}

const exit = () => {
  button.removeEventListener('click', handleToggleClick)
  menuLinks.forEach(link => link.removeEventListener('click', handleMenuLinkClick))
}

export default {
  enter,
  exit
}
