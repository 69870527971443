import { reset as resetLoader } from './loading.js'

const router = routes => {
  // set scroll restoration to manual
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual'
  }

  // cache root node
  const root = document.getElementById('root')

  // current route object
  let active

  // browser action flag (popstate)
  let browser

  const run = (path = window.location.pathname) => {
    // find the matching route URL
    const matchURL = Object
      .keys(routes)
      .find(route => route === path)

    // if no match found, show 404
    if (!matchURL) {
      console.log('404')
      return
    }

    // cache matching route
    const matchRoute = routes[matchURL]

    // proceed with route object
    const handler = active
      ? swap
      : initial

    handler(path, matchRoute)
  }

  const initial = async (path, route) => {
    // replace existing history entry
    window.history.replaceState({ path: path }, '', path)

    // update active route
    active = route

    // call new route enter
    await route.enter(root, true)
  }

  const swap = async (path, route) => {
    // push history entry, unless the browser action was used
    if (!browser) {
      window.history.pushState({ path: path }, '', path)
    }

    // reset browser action flag
    browser = false

    // call previous route exit
    await active.exit(root)

    // reset scroll position (while loader is visible)
    window.scrollTo(0, 0)

    // update active route
    active = route

    // call new route enter
    await route.enter(root, false)

    // reset the loader
    resetLoader()
  }

  const listen = () => {
    // bind click listener
    document.addEventListener('click', event => {
      // find closest anchor to click event target
      const anchor = event.target.closest('a')

      // if any of the following are true
        // 1. anchor not found
        // 2. anchor has no href
        // 3. anchor href is absolute
        // 4. anchor is external
      // then exit early
      const shouldExit = !anchor
        || !anchor.hasAttribute('href')
        || anchor.getAttribute('href').startsWith('http')         // covers "https" hrefs as well
        || anchor.getAttribute('target') === '_blank'

      if (shouldExit) return

      // prevent default behavior
      event.preventDefault()

      // get href attribute
      const href = anchor.getAttribute('href')

      // check for command or control key
      if (event.metaKey || event.ctrlKey) {
        // build URL
        const {
          host,
          protocol
        } = window.location

        // use "host" instead of "hostname" to preserve port number
        // this preserves local development server functionality
        const url = `${ protocol }//${ host }${ href }`

        // open in new tab or new window
        // how it opens is dependent on browser settings
        window.open(url)

        // exit early
        return
      }

      // route using href
      run(href)
    })

    // bind popstate listener
    window.addEventListener('popstate', event => {
      // set browser action flag
      browser = true

      // route using stored path
      run(event.state.path)
    })

    // parse initial route
    run()
  }

  return { listen }
}

export default router
