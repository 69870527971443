let root
let bars

const loading = () => {
  root = document.getElementById('loader')
  bars = [...root.children]

  const start = () => new Promise(resolve => {
    const handler = () => {
      bars[4].removeEventListener('transitionend', handler)
      resolve()
    }

    bars[4].addEventListener('transitionend', handler)

    window.requestAnimationFrame(() => root.classList.add('is-open'))
  })

  const end = () => new Promise(resolve => {
    const handler = () => {
      bars[0].removeEventListener('transitionend', handler)
      resolve()
    }

    bars[0].addEventListener('transitionend', handler)

    window.requestAnimationFrame(() => root.classList.add('is-closed'))
  })

  return {
    start,
    end
  }
}

export { loading }

const reset = () => {
  root.classList.remove('is-open', 'is-closed')
}

export { reset }
