let position = 0

const lock = () => {
  position = window.scrollY || window.pageYOffset

  document.body.style.overflow = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.top = `-${ position }px`
  document.body.style.width = '100%'
}

const unlock = () => {
  document.body.removeAttribute('style')
  window.scrollTo(0, position)
}

export default {
  lock,
  unlock
}
