import {
  setTitle,
  setDesc,
  setThemeColor
} from '../utils/head.js'

import hero from '../shared/hero.js'
import inview from '../providers/inview.js'
import jump from '../shared/jump.js'
import { loading } from './loading.js'
import menu from '../shared/menu.js'
import rings from '../shared/rings.js'

const shared = [
  hero,
  inview,
  jump,
  menu
]

const loader = loading()

// helper to create routes that just load HTML
const route = ({
  className,
  meta,
  path
}) => ({
  enter: async (root, initialLoad) => {
    // set meta
    setTitle(meta.title)
    setDesc(meta.desc)
    setThemeColor(meta.themeColor)

    // add root className
    root.classList.add(className)

    // load and inject content
    const response = await fetch(path)
    const html = await response.text()
    root.innerHTML = html

    // mount shared components
    shared.forEach(({ enter }) => enter && enter())

    // hide loader
    if (!initialLoad) {
      await loader.end()
    }

    // mount rings
    rings.enter()
  },
  exit: async root => {
    // show loader
    await loader.start()

    // remove root className
    root.classList.remove(className)

    // unmount shared components
    shared.forEach(({ exit }) => exit && exit())
  }
})

export default route
