const description = document.head.querySelector('meta[name="description"]')
const theme = document.head.querySelector('meta[name="theme-color"]')

export const setTitle = content => document.title = !content
  ? 'PAR21'
  : `PAR21 - ${ content }`

export const setDesc = content => description.setAttribute('content', content)

export const setThemeColor = content => theme.setAttribute('content', content)
