import cache from '../utils/cache.js'

const enter = () => {
  const root = document.querySelector('[data-hero-root]')

  if (!root) return

  const ref = root.querySelector('[data-hero-ref]')
  const ratio = root.dataset.heroRatio || 2
  const pad = (cache.window.height - ref.clientHeight) / window.parseInt(ratio, 10)
  root.style.paddingTop = `${ pad }px`
}

export default {
  enter
}
